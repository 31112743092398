import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/index_bg.png';
import _imports_1 from '@/assets/images/login_icon.png';
import _imports_2 from '@/assets/images/register_icon.png';
import _imports_3 from '@/assets/images/close.png';
const _hoisted_1 = {
  class: "index-page"
};
const _hoisted_2 = {
  class: "index-head"
};
const _hoisted_3 = {
  class: "index-head__card"
};
const _hoisted_4 = {
  class: "index-login__box"
};
const _hoisted_5 = {
  class: "index-login__content"
};
const _hoisted_6 = ["placeholder"];
const _hoisted_7 = ["placeholder"];
const _hoisted_8 = ["placeholder"];
const _hoisted_9 = {
  class: "index-login__content"
};
const _hoisted_10 = ["placeholder"];
const _hoisted_11 = ["placeholder"];
const _hoisted_12 = ["placeholder"];
const _hoisted_13 = ["placeholder"];
const _hoisted_14 = ["placeholder"];
import { ref } from 'vue';
import { register, login } from '@/api';
import { showLoadingToast, showToast } from 'vant';
import { useUserStore } from '@/store/user';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Index',
  setup(__props) {
    const {
      t
    } = useI18n();
    const showLogin = ref(false);
    const showRegister = ref(false);
    const phone = ref('');
    const password = ref('');
    const confirm_password = ref('');
    const pay_password = ref('');
    const invite_code = ref('');
    const userStore = useUserStore();
    const _register = async () => {
      if (!phone.value) {
        return showToast(t('Please enter your mobile number'));
      }
      if (!password.value) {
        return showToast(t('Please enter your password'));
      }
      if (confirm_password.value != password.value) {
        return showToast(t('The two passwords are inconsistent'));
      }
      if (!pay_password.value) {
        return showToast(t('Please enter the withdrawal password'));
      }
      if (!invite_code.value) {
        return showToast(t('Please enter the invitation code'));
      }
      showLoadingToast();
      const {
        code,
        msg,
        data
      } = await register({
        phone: phone.value,
        password: confirm_password.value,
        pay_password: pay_password.value,
        invite_code: invite_code.value
      });
      showToast(msg);
      if (code === 200) {
        // userStore.setToken(data.token);
        // userStore.setUser(data.user);
        showRegister.value = false;
        showLogin.value = true;
      }
    };
    const _login = async () => {
      if (!phone.value) {
        return showToast(t('Please enter your mobile number'));
      }
      if (!password.value) {
        return showToast(t('Please enter your password'));
      }
      if (confirm_password.value != password.value) {
        return showToast(t('The two passwords are inconsistent'));
      }
      showLoadingToast();
      const {
        code,
        msg,
        data
      } = await login({
        phone: phone.value,
        password: confirm_password.value
      });
      showToast(msg);
      if (code === 200) {
        userStore.setToken(data.token);
        userStore.setUser(data.user);
        userStore.setIpInfo(data.ip_Info);
        router.push('/home');
      }
    };
    return (_ctx, _cache) => {
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[16] || (_cache[16] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Explore the possibilities')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('We are the best welfare society in the world in 2024, so come and explore')), 1)])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "index-login__btn mb-25",
        onClick: _cache[0] || (_cache[0] = $event => showLogin.value = true)
      }, [_cache[17] || (_cache[17] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Log in')), 1)]), _createElementVNode("div", {
        class: "index-login__btn",
        onClick: _cache[1] || (_cache[1] = $event => showRegister.value = true)
      }, [_cache[18] || (_cache[18] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Register')), 1)])]), _createVNode(_component_van_overlay, {
        show: showLogin.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "index-login__dialog",
          onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Log in')), 1), _withDirectives(_createElementVNode("input", {
          type: "number",
          placeholder: _ctx.$t('Enter mobile number'),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => phone.value = $event)
        }, null, 8, _hoisted_6), [[_vModelText, phone.value]]), _withDirectives(_createElementVNode("input", {
          type: "password",
          placeholder: _ctx.$t('Enter password'),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => password.value = $event)
        }, null, 8, _hoisted_7), [[_vModelText, password.value]]), _withDirectives(_createElementVNode("input", {
          type: "password",
          placeholder: _ctx.$t('Confirm password'),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => confirm_password.value = $event)
        }, null, 8, _hoisted_8), [[_vModelText, confirm_password.value]]), _createElementVNode("div", {
          class: "index-login__btn1",
          onClick: _cache[5] || (_cache[5] = $event => _login())
        }, _toDisplayString(_ctx.$t('Log in')), 1)]), _createElementVNode("img", {
          src: _imports_3,
          alt: "",
          onClick: _cache[6] || (_cache[6] = $event => showLogin.value = false)
        })])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showRegister.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "index-login__dialog",
          onClick: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Register')), 1), _withDirectives(_createElementVNode("input", {
          type: "number",
          placeholder: _ctx.$t('Enter mobile number'),
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => phone.value = $event)
        }, null, 8, _hoisted_10), [[_vModelText, phone.value]]), _withDirectives(_createElementVNode("input", {
          type: "password",
          placeholder: _ctx.$t('Enter password'),
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => password.value = $event)
        }, null, 8, _hoisted_11), [[_vModelText, password.value]]), _withDirectives(_createElementVNode("input", {
          type: "password",
          placeholder: _ctx.$t('Confirm password'),
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => confirm_password.value = $event)
        }, null, 8, _hoisted_12), [[_vModelText, confirm_password.value]]), _withDirectives(_createElementVNode("input", {
          type: "password",
          placeholder: _ctx.$t('Withdraw password'),
          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => pay_password.value = $event)
        }, null, 8, _hoisted_13), [[_vModelText, pay_password.value]]), _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.$t('Enter Invitation Code'),
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => invite_code.value = $event)
        }, null, 8, _hoisted_14), [[_vModelText, invite_code.value]]), _createElementVNode("div", {
          class: "index-login__btn1",
          onClick: _cache[13] || (_cache[13] = $event => _register())
        }, _toDisplayString(_ctx.$t('Register')), 1)]), _createElementVNode("img", {
          src: _imports_3,
          alt: "",
          onClick: _cache[14] || (_cache[14] = $event => showRegister.value = false)
        })])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};