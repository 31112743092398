import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/contract_us1.png';
import _imports_1 from '@/assets/images/tg.png';
const _hoisted_1 = {
  class: "contract-us"
};
const _hoisted_2 = {
  class: "contract-us__content"
};
const _hoisted_3 = {
  class: "contract-us__btns"
};
import { router } from '@/router';
import { CustomerService } from '@/api';
import { ref } from 'vue';
export default {
  __name: 'ContactUs',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const customerServiceData = ref(null);
    const _CustomerService = async () => {
      const {
        data
      } = await CustomerService();
      customerServiceData.value = data;
    };
    _CustomerService();
    function getRandomElement(arr) {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    }
    const openChat = urls => {
      window.open(getRandomElement(urls), '_blank');
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-transparent__bg",
        fixed: "",
        title: _ctx.$t('Contact Us'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "contract-us__head"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Select contact information')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => openChat(customerServiceData.value.telegram))
      }, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Telegram')), 1)])])])]);
    };
  }
};