import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/card.png';
import _imports_1 from '@/assets/images/right2.png';
const _hoisted_1 = {
  class: "withdrawal"
};
const _hoisted_2 = {
  class: "withdrawal-head"
};
const _hoisted_3 = {
  class: "withdrawal-content"
};
const _hoisted_4 = {
  class: "withdrawal-amount__label"
};
const _hoisted_5 = {
  class: "withdrawal-amount__label"
};
const _hoisted_6 = {
  class: "withdrawal-card__box"
};
const _hoisted_7 = {
  class: "withdrawal-amount__tips"
};
const _hoisted_8 = {
  class: "withdrawal-pwd__title"
};
const _hoisted_9 = {
  class: "booking-confirm__box"
};
const _hoisted_10 = {
  class: "booking-confirm__content"
};
const _hoisted_11 = {
  class: "booking-confirm__btns"
};
import { router } from '@/router';
import { computed, ref } from 'vue';
import { useUserStore } from '@/store/user';
import { formatBankCardNumber } from '@/utils';
import { showLoadingToast, showToast } from 'vant';
import { BasicConfig, Withdraw } from '@/api';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Withdrawal',
  setup(__props) {
    const {
      t
    } = useI18n();
    const amount = ref('');
    const userStore = useUserStore();
    userStore.updateWallet();
    userStore.updateBankData();
    const wallet = computed(() => userStore.wallet);
    const bankData = computed(() => userStore.bankData);
    const password = ref('');
    const showPassword = ref(false);
    const showConfirm = ref(false);
    const onClickLeft = () => {
      router.back();
    };
    const tapWithdrawBtn = () => {
      if (!bankData.value) {
        router.push('/BindBank');
        return;
      }
      if (!amount.value) {
        return showToast(t('Please enter the withdrawal amount'));
      }
      if (amount.value < dataConfig.value.min) {
        showConfirm.value = true;
        return false;
      }
      showPassword.value = true;
    };
    const _Withdraw = () => {
      if (!password.value || password.value.length !== 6) {
        return showToast(t('Please enter a 6-digit password'));
      }
      showLoadingToast();
      Withdraw({
        amount: amount.value,
        pay_password: password.value
      }).then(({
        msg
      }) => {
        showToast(msg);
        showPassword.value = false;
        userStore.updateWallet();
      });
    };
    const goToBindCard = () => {
      if (!bankData.value.card) router.push('/BindBank');
    };
    const dataConfig = ref({
      max: 0,
      min: 0
    });
    const getConfig = () => {
      BasicConfig().then(res => {
        dataConfig.value.min = parseFloat(res.data.withdrawal_min);
        dataConfig.value.max = parseFloat(res.data.withdrawal_max);
      });
    };
    getConfig();
    const btnActive = computed(() => {
      return amount.value && amount.value >= dataConfig.value.min && bankData.value.card;
    });
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_password_input = _resolveComponent("van-password-input");
      const _component_van_number_keyboard = _resolveComponent("van-number-keyboard");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-transparent__bg",
        fixed: "",
        title: _ctx.$t('Withdrawal'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('My assets')), 1), _createElementVNode("p", null, "₹ " + _toDisplayString(wallet.value.balance), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Withdraw mode')), 1), _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "withdrawal-amount__input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => amount.value = $event)
      }, null, 512), [[_vModelText, amount.value]]), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('Withdrawal amount')), 1), _createElementVNode("div", _hoisted_6, [_cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("span", {
        class: "no-card",
        onClick: _cache[1] || (_cache[1] = $event => goToBindCard())
      }, _toDisplayString(bankData.value.card ? _unref(formatBankCardNumber)(bankData.value.card) : _unref(t)('Please bind your bank card first')), 1), _createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.$t('Bank')) + " ", 1), _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1))])]), _createElementVNode("div", {
        class: "withdrawal-amount__btn",
        onClick: tapWithdrawBtn,
        style: _normalizeStyle({
          'opacity': btnActive.value ? 1 : .3
        })
      }, "Withdraw", 4), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Withdrawal rules')), 1), _createElementVNode("p", null, "1 • " + _toDisplayString(_ctx.$t('The minimum withdrawal amount for a single transaction is ₹500.00;')), 1), _createElementVNode("p", null, "2 • " + _toDisplayString(_ctx.$t('Repeated withdrawal applications are not accepted. Please wait until the withdrawal is successful or failed before applying again.')), 1), _createElementVNode("p", null, "3 • " + _toDisplayString(_ctx.$t('Cash withdrawal working hours are 10:00am-10:00pm.')), 1)])]), _createVNode(_component_van_popup, {
        show: showPassword.value,
        "onUpdate:show": _cache[5] || (_cache[5] = $event => showPassword.value = $event),
        position: "bottom"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_component_van_icon, {
          name: "arrow-left",
          onClick: _cache[2] || (_cache[2] = $event => showPassword.value = false)
        }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('WITHDRAWAL PASSWORD')), 1)]), _createVNode(_component_van_password_input, {
          value: password.value
        }, null, 8, ["value"]), _createElementVNode("div", {
          class: "withdrawal-amount__confirm",
          onClick: _cache[3] || (_cache[3] = $event => _Withdraw())
        }, "Confirm"), _createVNode(_component_van_number_keyboard, {
          style: {
            "position": "relative"
          },
          modelValue: password.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => password.value = $event),
          "random-key-order": "",
          show: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showConfirm.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('System message')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('The minimum withdrawal amount for a single transaction is ₹{min};', {
          min: dataConfig.value.min
        })), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
          onClick: _cache[6] || (_cache[6] = $event => showConfirm.value = false)
        }, _toDisplayString(_ctx.$t('Confirm')), 1)])])])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};