import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "booking-record"
};
const _hoisted_2 = {
  class: "booking-record__menu"
};
const _hoisted_3 = {
  class: "booking-record__box"
};
const _hoisted_4 = {
  class: "booking-record__scroll"
};
const _hoisted_5 = {
  class: "booking-record__container"
};
const _hoisted_6 = {
  class: "booking-record__head"
};
const _hoisted_7 = {
  key: 0,
  class: "premium-span"
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 2,
  class: "red-text"
};
const _hoisted_10 = {
  class: "booking-record__item"
};
const _hoisted_11 = {
  class: "booking-record__item"
};
const _hoisted_12 = {
  class: "van-ellipsis"
};
const _hoisted_13 = {
  class: "booking-record__item"
};
const _hoisted_14 = {
  class: "booking-record__item"
};
const _hoisted_15 = {
  class: "fw-700"
};
const _hoisted_16 = {
  class: "booking-record__item"
};
const _hoisted_17 = {
  class: "booking-record__item"
};
const _hoisted_18 = ["onClick"];
import { router } from '@/router';
import { ConfirmOrder, OrderList } from '@/api';
import { ref, watch } from 'vue';
import { showLoadingToast, showToast } from 'vant';
import PayPassword from '@/components/PayPassword.vue';
export default {
  __name: 'Record',
  setup(__props) {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const status = ref('');
    const showPayPwd = ref(false);
    const onClickLeft = () => {
      router.back();
    };
    const _OrderList = () => {
      OrderList({
        page: page.value,
        status: status.value
      }).then(({
        data
      }) => {
        if (data.current_page === 1) {
          list.value = data.data;
        } else {
          list.value = list.value.concat(data.data);
        }
        loading.value = false;
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    const _ConfirmOrder = item => {
      if (item.status) return false;
      showPayPwd.value = true;
    };
    const payPwdHandle = pwd => {
      showLoadingToast();
      ConfirmOrder({
        pay_password: pwd
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          showPayPwd.value = false;
          page.value = 1;
          _OrderList();
        }
      });
    };
    watch(status, () => {
      list.value = [];
      loading.value = true;
      page.value = 1;
      _OrderList();
    });
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PayPassword, {
        "show-password": showPayPwd.value,
        onSubmit: payPwdHandle,
        onClose: _cache[0] || (_cache[0] = $event => showPayPwd.value = false)
      }, null, 8, ["show-password"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        placeholder: "",
        title: _ctx.$t('Record'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass({
          'active': status.value === ''
        }),
        onClick: _cache[1] || (_cache[1] = $event => status.value = '')
      }, _toDisplayString(_ctx.$t('All')), 3), _createElementVNode("div", {
        class: _normalizeClass({
          'active': status.value === 0
        }),
        onClick: _cache[2] || (_cache[2] = $event => status.value = 0)
      }, _toDisplayString(_ctx.$t('Pending')), 3), _createElementVNode("div", {
        class: _normalizeClass({
          'active': status.value === 1
        }),
        onClick: _cache[3] || (_cache[3] = $event => status.value = 1)
      }, _toDisplayString(_ctx.$t('Completed')), 3)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[4] || (_cache[4] = $event => loading.value = $event),
        finished: finished.value,
        "loading-text": _ctx.$t('Loading...'),
        "finished-text": _ctx.$t('No more data'),
        onLoad: _OrderList
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "booking-record__content",
            key: item.id
          }, [_cache[5] || (_cache[5] = _createElementVNode("div", {
            class: "booking-record__bg"
          }, null, -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [item.premium_order == 1 ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('Premium order')), 1)) : _createCommentVNode("", true), item.premium_order == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Regular order")) : _createCommentVNode("", true), item.type == 2 ? (_openBlock(), _createElementBlock("span", _hoisted_9, "x" + _toDisplayString(item.times * 100) + " " + _toDisplayString(_ctx.$t('commission')), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order number')) + ":", 1), _createElementVNode("span", null, _toDisplayString(item.order_no), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order name')) + ":", 1), _createElementVNode("span", _hoisted_12, _toDisplayString(item.oreder_name), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order quantity')) + ":", 1), _createElementVNode("span", null, _toDisplayString(item.order_quantity), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Total amount')) + ":", 1), _createElementVNode("span", _hoisted_15, "₹ " + _toDisplayString(item.type === '3' ? item.reward : item.price), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Gross commission')) + ":", 1), _createElementVNode("span", null, "₹ " + _toDisplayString(item.gross_commission), 1)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Booking time')) + ":", 1), _createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", {
            class: "booking-record__btn",
            onClick: $event => payPwdHandle(item)
          }, _toDisplayString(item.status ? _ctx.$t('Dispose') : _ctx.$t('Submit')), 9, _hoisted_18)])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "loading-text", "finished-text"])])])])], 64);
    };
  }
};