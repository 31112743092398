import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "webview"
};
const _hoisted_2 = ["innerHTML"];
import { router } from '@/router';
import { useRoute } from 'vue-router';
import { UserAgreement, Privacy, BasicRecharge, BasicWithdrawal } from '@/api';
import { ref } from 'vue';
export default {
  __name: 'Webview',
  setup(__props) {
    const route = useRoute();
    const title = route.query.title;
    const webviewHtml = ref('');
    const onClickLeft = () => {
      router.back();
    };
    const _getWebview = () => {
      switch (title) {
        case 'Privacy':
          Privacy().then(({
            data
          }) => {
            webviewHtml.value = data;
          });
          break;
        case 'User agreement':
          UserAgreement().then(({
            data
          }) => {
            webviewHtml.value = data;
          });
          break;
        case 'Recharge':
          BasicRecharge().then(({
            data
          }) => {
            webviewHtml.value = data;
          });
          break;
        case 'Withdraw':
          BasicWithdrawal().then(({
            data
          }) => {
            webviewHtml.value = data;
          });
          break;
        default:
          console.log('default');
          break;
      }
    };
    _getWebview();
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        fixed: "",
        placeholder: "",
        title: _unref(title),
        "left-arrow": "",
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", {
        class: "webview-content",
        innerHTML: webviewHtml.value
      }, null, 8, _hoisted_2)]);
    };
  }
};